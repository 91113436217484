<template>
    <div>
        <div class="container-fluid blue-bg">
            <div class="row">
                <div class="col-md-5 col-lg-4 text-right">
                    <h3>Buchungsübersicht</h3>
                </div>
                <div class="col-md-7 col-xl-8">
                    <a href="/" class="button black float-right">Ändern</a>
                </div>
            </div>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Fahrzeugtyp:</dt>
                <dd class="col-md-6 offset-md-1">{{ translations[car_type] }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Reinigung:</dt>
                <dd class="col-md-6 offset-md-1">{{ translations[cleaning_type] }}</dd>
            </dl>
            <dl class="row" v-if="additional_service.length">
                <dt class="col-md-5 col-lg-4 text-right">zusätzl. Buchungen:</dt>
                <dd class="col-md-6 offset-md-1">{{ joinAdditionalService() }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Datum:</dt>
                <dd class="col-md-6 offset-md-1">{{createDate()}}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Abgabe:</dt>
                <dd class="col-md-6 offset-md-1">{{createTime()}}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Rückgabe:</dt>
                <dd class="col-md-6 offset-md-1">{{createEndTime()}}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Gesamtpreis:</dt>
                <dd class="col-md-6 offset-md-1"><span class="price demi" v-html="getTotalPrice()"></span> <span class="tax">inkl. 19 % Mehrwertsteuer</span></dd>
            </dl>
        </div>
    </div>
</template>
<script>
    import {parseISO, format} from 'date-fns'
    import { de } from 'date-fns/locale';

    export default {
        name: 'CartDetails',

        components: {
        },
        data() {
            return {
                car_type: this.$store.state.cart.product.split("_")[0],
                cleaning_type: this.$store.state.cart.product.split("_")[1],
                additional_service: this.$store.state.cart.additional_service,
                booking_time: this.$store.state.cart.booking_time,
                booking_end_time: this.$store.state.cart.booking_end_time,
                translations: {
                    'compact': 'Kleinwagen',
                    'combi': 'Limousine/Kombi/Cabrio',
                    'suv': 'SUV',
                    'bus': 'Bus/Transporter',
                    'bike': 'Motorrad',
                    'premium': 'Premiumpaket',
                    'comfort': 'Komfortpaket',
                    'inside': 'Innenpaket',
                    'outside': 'Außenpaket',
                    'basis': 'Basispaket',
                    'winter': 'Winterpaket',
                },
                actionWeek: false
            }
        },
        computed: {
          items() { return this.$store.state.items }
        },
        methods: {
            getTotalPrice(){
                var cleaning = this.items[this.car_type][this.cleaning_type].price,
                    additional_service = this.items[this.car_type][this.cleaning_type].additional_service,
                    additional = 0,
                      hasCaramic = false;

                if(this.additional_service.length > 0){
                    this.additional_service.forEach(value=>{
                        additional += additional_service[value].price;
                        if(value === "Keramikversiegelung"){
                            hasCaramic = true;
                          }
                    })
                }

                  if(this.actionWeek && this.cleaning_type === "outside" && hasCaramic){
                    var price = parseFloat(cleaning + additional);
                    price = (price * 75 ) / 100;
                    return `<span style="text-decoration:line-through">${parseFloat(cleaning + additional).toFixed(2)} €</span><br>${price.toFixed(2)} €`
                  }else{
                    return `${parseFloat(cleaning + additional).toFixed(2)} €`;
                  }
            },
            createDate(){
                return format(parseISO(this.booking_time), 'EEEEEE, dd. MMM. yyyy', { locale: de })
            },
            createTime(){

                if(parseInt(format(parseISO(this.booking_time), 'H')) < 12){
                    return 'zwischen 07:00 Uhr und 8:00 Uhr'
                }else{
                    return 'zwischen 11:00 Uhr und 12:00 Uhr'
                }
            },
            createEndTime(){
                if(parseInt(format(parseISO(this.booking_end_time), 'H')) >= 12){
                    return 'zwischen 17:00 Uhr und 18:00 Uhr'
                }else{
                    return 'zwischen 11:00 Uhr und 12:00 Uhr'
                }
            },
            joinAdditionalService(){
                return this.additional_service.join(", ");
            }
        },
        mounted() {
            if(new Date() < new Date('August 15, 2021 23:59:59')){
                this.actionWeek = true;
            }
        }
    }
</script>
<style lang="scss" scoped>

    .container-fluid{
        padding-left:140px;
        padding-right:140px;
        padding-top: 90px;

        @media(max-width: 768px) {
            padding-right: 15px;
                padding-left: 15px;
        }

        dt{
            font-weight: 600;
        }

        dd{
            font-weight: 400;;
        }

        dt,
        dd{
            font-size: 21px;
            letter-spacing: 0.53px;
            line-height: 20px;
        }
    }

</style>