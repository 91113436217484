<template>
    <div>
        <div class="container-fluid blue-bg">
            <div class="row">
                <div class="col-md-5 col-lg-4 text-right">
                    <h3>Buchungsübersicht</h3>
                </div>
                <div class="col-md-7 col-xl-8">
                    <a href="coupons" class="button black float-right">Ändern</a>
                </div>
            </div>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Gutschein Betrag:</dt>
                <dd class="col-md-6 offset-1">{{ coupon.couponValue }} €</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Lieferadresse:</dt>
                <dd class="col-md-6 offset-md-1" v-if="coupon.deliveryType == 'postal'">

                    <p>{{user_shipping_data.title}} {{user_shipping_data.lastname}} {{user_shipping_data.firstname}}</p>
                    <p>{{user_shipping_data.street}}</p>
                    <p>{{user_shipping_data.zipcode}} {{user_shipping_data.city}}</p>
                    <p>+49 {{user_shipping_data.phone}}</p>
                    <p>{{user_shipping_data.mail}}</p>

                </dd>
                <dd class="col-md-6 offset-md-1" v-if="coupon.deliveryType == 'pickup'">
                    <p>Selbstabholer</p>
                </dd>
                <dd class="col-md-6 offset-md-1" v-if="coupon.couponShipping == 'mail'">
                    <span> {{coupon.shippingMail}} </span>
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CouponCartDetails',

        components: {
        },
        data() {
            return {
                coupon: this.$store.state.cart,
                user_shipping_data: this.$store.state.user_shipping_data.user_shipping_data,
            }
        },
    }
</script>
<style lang="scss" scoped>

    .container-fluid{
        padding-left:140px;
        padding-right:140px;
        padding-top: 90px;

        @media(max-width: 768px) {
            padding-right: 15px;
                padding-left: 15px;
        }

        dt{
            font-weight: 600;
        }

        dd{
            font-weight: 400;;
        }

        dt,
        dd{
            font-size: 21px;
            letter-spacing: 0.53px;
            line-height: 20px;
        }
    }

</styles>